$(document).ready(function() {
	//Pull through all scripts in customScripts function
   	customScripts();
});

//Primary scripts function
function customScripts() {

/*  ==========================================================================
	Custom Events
	========================================================================== */
//Initialise Animate on Scroll
  	AOS.init({
	  	startEvent: 'load'
	});

    if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
     	var evt = document.createEvent('UIEvents');
     	evt.initUIEvent('resize', true, false, window, 0);
     	window.dispatchEvent(evt);
    } else {
       	window.dispatchEvent(new Event('resize'));
    }

    function addIEClasses() {
          var isIE = /*@cc_on!@*/false || !!document.documentMode;
          var isEdge = !isIE && !!window.StyleMedia;
          
          if (isIE || isEdge) {
            $('body').addClass('ie-styles');
          }
        }
        
        addIEClasses();

    //Main menu toggle
	$('#nav-toggle').on('click', function() {
		$('#navigation, #nav-toggle, body, #site-header').toggleClass('open');
	});

	//Main menu toggle
	$('#menu-item-199 > a').on('click', function(e) {
		e.preventDefault();
		$('#menu-item-199 .sub-menu, #subnav-toggle, #menu-item-185 >.sub-menu').toggleClass('open');
		$(this).toggleClass('sub-menu-open');
	});

/*  ==========================================================================
	jQuery Plugins
	========================================================================== */	

	//Slickslider
	$('.header-slideshow').slick({
	  	infinite: true,
	  	slidesToShow: 1,
	  	slidesToScroll: 1,
	  	fade: true,
	  	autoplay: true,
	  	dots: true,
	  	cssEase: 'linear',
	  	speed: 1000,
	  	pauseOnHover: false,
	  	appendArrows: $('.slick-arrows'),
	  	prevArrow : '<div class="slick-prev"></div>',
	    nextArrow : '<div class="slick-next"></div>'
	});

	//Isotope grid - Custom grid
	var $grid = $('.grid').isotope({
		itemSelector: '.item',
	});
	// layout Isotope after each image loads
	$grid.imagesLoaded().progress( function() {
	 	$grid.isotope('layout');
	});

	//Isotope grid - Gallery grid
	var $galgrid = $('.gallery').isotope({
		itemSelector: '.gallery-item',
	});

	// layout Isotope after each image loads
	$galgrid.imagesLoaded().progress( function() {
	 	$galgrid.isotope('layout');
	});

	// get Isotope instance
	// var iso = $galgrid.data('isotope');

	// // init Infinite Scroll
	// $galgrid.infiniteScroll({
	//   	// Infinite Scroll options...
	//   	path: '.pagination-next a',
	//   	append: '.gallery-item',
	//   	outlayer: iso,
	// });

	//Move.js - move social icons within the footer. 
	$('#site-footer .col-2 .social-links').move({
		breakpoint: 1101,
		oldLocation: '#site-footer .col-2',
		newLocation: '#site-footer .col-1 .copyright',
		methods: {
			o: 'appendTo',
			n: 'insertBefore'
		}
	});

	//Move.js - move newsletter block above bordered title within footer
	$('#site-footer .col-2').move({
		breakpoint: 601,
		oldLocation: '#site-footer .col-1',
		newLocation: '#site-footer .bordered-title',
		methods: {
			o: 'insertAfter',
			n: 'insertBefore'
		}
	});

	//Fancybox
	$('[data-fancybox="gallery"]').fancybox({
		animationEffect: 'fade',
		transitionEffect: "fade",
		loop: true,
		wheel: false,
	});


};


$(window).on('load', function() {
	setTimeout(function() {
		$(window).trigger('resize');
		console.log('resized');
	}, 3000);
});